import React, { useState, useEffect } from "react";
import { getDoc, doc } from "firebase/firestore";
import db from "./firebase";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import IosShareIcon from '@mui/icons-material/IosShare';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import "./styles.css";

const Logo = () => {
  return (
    <a className="text-lg tracking-tight text-black uppercase focus:outline-none focus:ring lg:text-2xl" href="https://worqhat.com">
      <img src="https://worqhat.com/resources/WorqHat%20TM%20Logo.png" alt="WorqHat Logo" className="focus:ring-0 h-10 md:h-16" />
    </a>
  );
};

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full mx-auto bg-white border-b 2xl:max-w-7xl">
      <div className="relative flex flex-col w-full p-5 mx-auto bg-white md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
        <div className="flex flex-row items-center justify-between lg:justify-start">
          <Logo />
          <button onClick={toggleMenu} className="inline-flex items-center justify-center p-2 text-gray-400 hover:text-black focus:outline-none focus:text-black md:hidden">
            <svg className="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path className={isOpen ? 'hidden' : 'inline-flex'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
              <path className={!isOpen ? 'hidden' : 'inline-flex'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <nav className={isOpen ? 'flex-col flex-grow py-10 md:flex lg:py-0 md:justify-end md:flex-row' : 'flex-col flex-grow hidden py-10 md:flex lg:py-0 md:justify-end md:flex-row'}>
          <ul className="space-y-2 list-none md:space-y-0 md:items-center md:inline-flex">
            <li>
              <a href="https://worqhat.com" className="px-2 py-8 text-lg text-gray-500 border-b-2 border-transparent lg:px-6 md:px-3 hover:text-blue-600 hover:border-blue-500">
                WorqHat AI
              </a>
            </li>
            <li>
              <a href="https://worqhat.com/playground" className="px-2 py-8 text-lg text-gray-500 border-b-2 border-transparent lg:px-6 hover:border-blue-500 md:px-3 hover:text-blue-600">
                AI Playground
              </a>
            </li>
            <li>
              <a href="https://learn.worqhat.com" className="px-2 py-8 text-lg text-gray-500 border-b-2 border-transparent lg:px-6 md:px-3 hover:text-blue-600 hover:border-blue-500">
                WorqHat Learner's Academy
              </a>
            </li>
            <li>
              <a href="https://worqhat.com/pricing" className="px-2 py-8 text-lg text-gray-500 border-b-2 border-transparent lg:px-6 md:px-3 hover:text-blue-600 hover:border-blue-500">
                Pricing
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

const errorText = (id) => {
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-indigo-600">Certificate Id: {id}</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-red-900 sm:text-5xl">Certificate not found</h1>
        <p className="mt-6 text-base leading-7 text-red-600">Sorry, we couldn’t find the Certificate Details you were looking for.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a href="http://worqhat.com" className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Visit WorqHat</a>
          <a href="https://worqhat.com/contact-us" className="text-sm font-semibold text-gray-900">Contact support <span aria-hidden="true">&rarr;</span></a>
        </div>
      </div>
    </main>
  )
};

const loadingText = (id) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 className="text-center text-white text-xl font-semibold">Looking for Certificate with Id: {id}</h2>
      <p className="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p>
    </div>
  )
};

const successText = (data, id) => {
  return (
    <section className="relative items-center w-full bg-white py-12">
      <div className="text-4xl mx-12 font-semibold">
        {data.projectName}
      </div>
      <div className="relative items-center w-full px-5 py-24 mx-auto md:px-12 lg:px-16 max-w-7xl">
        <div className="relative flex-col items-start m-auto align-middle">
          <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-24">
            <div className="relative items-center gap-8 m-auto lg:inline-flex md:order-first">
              <div className="max-w-md text-center lg:text-left">
                <div>
                  <p className="text-2xl tracking-tight text-black sm:text-4xl">
                    Completed by &nbsp;
                    <br></br>
                    <span className="font-semibold mt-3">
                      {data.name}
                    </span>
                  </p>
                  <p className="text-xl tracking-tight text-black sm:text-2xl mt-4">
                    Grade Achieved &nbsp;
                    <span className="font-semibold">
                      {data.grade}%
                    </span>
                  </p>
                  <p className="text-lg tracking-tight text-black sm:text-xl mt-4">
                    <span className="font-semibold">
                      {data.date.seconds && (
                        <>
                          {new Date(data.date.seconds * 1000).toLocaleString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                          })}
                        </>
                      )}
                    </span>
                  </p>
                  <p className="max-w-xl mt-4 text-base tracking-tight text-gray-600">
                    {data.name}'s Project is verified. WorqHat certifies their successful completion of <span className="font-semibold">{data.projectName}</span> with a grade of {data.grade}%. The GitHub Commit id&nbsp;
                    <a href={data.githubCommitLink} target="_blank" rel="noopener noreferrer" className="underline underline-offset-4 text-blue-500">
                      {data.gitCommitId}
                    </a> was considered as the last commit that was used to check and grade the project.
                  </p>
                  <p className="text-lg tracking-tight text-black sm:text-xl mt-4">
                    Verification Id &nbsp;
                    <span className="font-semibold">
                      {id}
                    </span>
                  </p>
                </div>
                <div className="flex justify-center gap-3 mt-10 lg:justify-start">
                  <p className="inline-flex items-center justify-center text-sm font-semibold text-black duration-200 hover:text-blue-500 focus:outline-none focus-visible:outline-gray-600">
                    <span>
                      Visit&nbsp;
                      <a href={data.projectLink} target="_blank" rel="noopener noreferrer" className="underline underline-offset-8">
                        {data.projectName}
                      </a>
                    </span>
                    <ion-icon className="flex-none w-3 h-3 ml-3 fill-blue-600 group-active:fill-current md hydrated" name="arrow-forward-outline" role="img" aria-label="arrow forward outline"></ion-icon>
                  </p>
                </div>
              </div>
            </div>
            <div className="order-first block w-full">
              <img className="object-cover object-center w-full mx-auto bg-gray-300 lg:ml-auto " alt={`Project Certificate for ${data.name}`} src={`https://worqhat.s3.ap-south-1.amazonaws.com/certifications/image/${id}`}></img>
              {
                window.location.pathname.split('/')[1] === 'share' ? (
                  downloadText(id, data.name, data.projectName)
                ) : (
                  <div></div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

const shareToLinkedIn = (name, projectName) => {
  const url = encodeURIComponent(window.location.href); // Replace with your desired URL
  const title = encodeURIComponent(`Project Certificate for ${name}`); // Replace with your desired title
  const summary = encodeURIComponent(`${name} has successfully completed a project in ${projectName} with WorqHat Learner's Academy.`); // Replace with your desired summary

  const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}&title=${title}&summary=${summary}`;

  window.open(linkedInShareUrl, '_blank');
};

const downloadText = (id, name, projectName) => {
  return (
    <div className="justify-between items-center pt-0 space-y-4 sm:flex sm:space-y-0 mt-4">
      <div className="items-center space-y-4 sm:space-x-4 sm:flex sm:space-y-0 text-center w-full">
        <button onClick={() => {
          shareToLinkedIn(name, projectName);
        }}
          type="button"
          className="py-2 px-4 w-full font-medium text-gray-500 bg-white rounded-lg border border-gray-200 sm:w-auto hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 hover:text-gray-900 focus:z-10"
        >
          Share Certificate on LinkedIn <IosShareIcon className="ml-2" />
        </button>
        <button
          type="button"
          className="py-2 px-4 w-full font-medium text-gray-500 bg-white rounded-lg border border-gray-200 sm:w-auto hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 hover:text-gray-900 focus:z-10" onClick={() => {
            navigator.clipboard.writeText(`https://verify.worqhat.com/share/${id}`);
            alert("The link to your Certificate has been copied to your clipboard. You can now share it with anyone.")
          }}
        >
          Copy <CopyAllIcon className="ml-2" />
        </button>
        <a
          href={`https://worqhat.s3.ap-south-1.amazonaws.com/certifications/pdf/${id}`}
          target="_blank"
          rel="noopener noreferrer"
          id="confirm-button"
          type="button"
          className="py-2 px-4 w-full font-medium text-center text-white rounded-lg bg-blue-700 sm:w-auto hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
        >
          Download Certificate <CloudDownloadOutlinedIcon className="ml-2" />
        </a>
      </div>
    </div>
  );
};


const SecondPage = () => {
  const id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "certifications", id);
      console.log(id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setData(docSnap.data());
        document.title = `${docSnap.data().name} | WorqHat Learner's Academy`;
        document.querySelector('meta[name="description"]').setAttribute("content", `${docSnap.data().name} has successfully completed a project in ${docSnap.data().projectName} with WorqHat Learner's Academy.`);
      } else {
        console.log("No such document!");
        document.title = `Certificate Not Found | WorqHat Learner's Academy`;
        document.querySelector('meta[name="description"]').setAttribute("content", `The certificate with id ${id} was not found. Please check the id and try again.`);
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  return (
    <div>
      <Navbar />
      {loading ? (
        loadingText(id)
      ) : (
        data ? (
          successText(data, id)
        ) : (
          errorText(id)
        )
      )}
    </div>
  );
};

export default SecondPage;
