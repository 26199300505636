import React, { useState } from "react";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import db from "./firebase";

const FirstPage = () => {

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');

    const [projectName, setProjectName] = useState('');
    const [projectNameError, setProjectNameError] = useState('');

    const [projectLink, setProjectLink] = useState('');
    const [projectLinkError, setProjectLinkError] = useState('');

    const [githubCommitId, setGithubCommitId] = useState('');
    const [githubCommitIdError, setGithubCommitIdError] = useState('');

    const [githubCommitLink, setGithubCommitLink] = useState('');
    const [githubCommitLinkError, setGithubCommitLinkError] = useState('');

    const [grade, setGrade] = useState('');
    const [gradeError, setGradeError] = useState('');

    const [certificateImageLink, setCertificateImageLink] = useState('');
    const [certificateImageLinkError, setCertificateImageLinkError] = useState('');

    const [certificatePDFLink, setCertificatePDFLink] = useState('');
    const [certificatePDFLinkError, setCertificatePDFLinkError] = useState('');


    const [randomId, setRandomId] = useState("");
    const [randomIdError, setRandomIdError] = useState("");

    const generateId = (e) => {
        e.preventDefault();
        const alphanumeric = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const idLength = Math.floor(Math.random() * 4) + 6;
        let id = '';
        for (let i = 0; i < idLength; i++) {
            const randomIndex = Math.floor(Math.random() * alphanumeric.length);
            id += alphanumeric[randomIndex];
        }
        setRandomId(id);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!name) {
            setNameError("Please enter a name");
            return;
        } else {
            setNameError("");
        }

        // Validate projectName field
        if (!projectName) {
            setProjectNameError("Please enter a project name");
            return;
        } else {
            setProjectNameError("");
        }

        // Validate projectLink field
        if (!projectLink) {
            setProjectLinkError("Please enter a project link");
            return;
        } else {
            setProjectLinkError("");
        }

        // Validate githubCommitId field
        if (!githubCommitId) {
            setGithubCommitIdError("Please enter a GitHub commit ID");
            return;
        } else {
            setGithubCommitIdError("");
        }

        // Validate githubCommitLink field
        if (!githubCommitLink) {
            setGithubCommitLinkError("Please enter a GitHub commit link");
            return;
        } else {
            setGithubCommitLinkError("");
        }

        // Validate grade field
        if (!grade) {
            setGradeError("Please enter a grade");
            return;
        } else {
            setGradeError("");
        }

        // Validate certificateImageLink field
        if (!certificateImageLink) {
            setCertificateImageLinkError("Please enter a certificate image link");
            return;
        } else {
            setCertificateImageLinkError("");
        }

        // Validate certificatePDFLink field
        if (!certificatePDFLink) {
            setCertificatePDFLinkError("Please enter a certificate PDF link");
            return;
        } else {
            setCertificatePDFLinkError("");
        }


        if (!randomId) {
            setRandomIdError(" Please Generate an ID before Submitting");
            return;
        } else {
            setRandomIdError("");
        }

        try {
            await setDoc(doc(db, "certifications", randomId), {
                name: name,
                random_id: randomId,
                projectName: projectName,
                projectLink: projectLink,
                gitCommitId: githubCommitId,
                githubCommitLink: githubCommitLink,
                grade: grade,
                certificateImageLink: certificateImageLink,
                certificatePDFLink: certificatePDFLink,
                date: serverTimestamp(),
            });

            window.alert('Form has been submitted');
            setName("");
            setRandomId("");
            setProjectName("");
            setProjectLink("");
            setGithubCommitId("");
            setGithubCommitLink("");
            setGrade("");
            setCertificateImageLink("");
            setCertificatePDFLink("");

        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    const id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    if (id === "worqhat_0508") {
        return (
            <div style={{ textAlign: "center" }}>
                <nav style={{ height: "80px", backgroundColor: "blue", paddingTop: "20px" }}>
                    <h1 style={{ fontSize: "36px", color: "white", marginTop: "auto" }}>Worqhat</h1>
                </nav>
                <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <div style={{ margin: "20px" }}>
                        <label htmlFor="name">Name: </label>
                        <input type="text" id="name" placeholder="Enter Name" onChange={(e) => setName(e.target.value)} />
                        <span style={{ color: "red" }}>{nameError}</span>
                    </div>

                    <div style={{ margin: "20px" }}>
                        <label htmlFor="projectName">Project Name: </label>
                        <input type="text" id="projectName" placeholder="Enter Project Name" onChange={(e) => setProjectName(e.target.value)} />
                        <span style={{ color: "red" }}>{projectNameError}</span>
                    </div>

                    <div style={{ margin: "20px" }}>
                        <label htmlFor="projectLink">Project Link: </label>
                        <input type="text" id="projectLink" placeholder="Enter Project Link" onChange={(e) => setProjectLink(e.target.value)} />
                        <span style={{ color: "red" }}>{projectLinkError}</span>
                    </div>

                    <div style={{ margin: "20px" }}>
                        <label htmlFor="githubCommitId">GitHub Commit ID: </label>
                        <input type="text" id="githubCommitId" placeholder="Enter GitHub Commit ID" onChange={(e) => setGithubCommitId(e.target.value)} />
                        <span style={{ color: "red" }}>{githubCommitIdError}</span>
                    </div>

                    <div style={{ margin: "20px" }}>
                        <label htmlFor="githubCommitLink">GitHub Commit Link: </label>
                        <input type="text" id="githubCommitLink" placeholder="Enter GitHub Commit Link" onChange={(e) => setGithubCommitLink(e.target.value)} />
                        <span style={{ color: "red" }}>{githubCommitLinkError}</span>
                    </div>

                    <div style={{ margin: "20px" }}>
                        <label htmlFor="grade">Grade: </label>
                        <input type="text" id="grade" placeholder="Enter Grade" onChange={(e) => setGrade(e.target.value)} />
                        <span style={{ color: "red" }}>{gradeError}</span>
                    </div>

                    <div style={{ margin: "20px" }}>
                        <label htmlFor="certificateImageLink">Certificate Image Link: </label>
                        <input type="text" id="certificateImageLink" placeholder="Enter Certificate Image Link" onChange={(e) => setCertificateImageLink(e.target.value)} />
                        <span style={{ color: "red" }}>{certificateImageLinkError}</span>
                    </div>

                    <div style={{ margin: "20px" }}>
                        <label htmlFor="certificatePDFLink">Certificate PDF Link: </label>
                        <input type="text" id="certificatePDFLink" placeholder="Enter Certificate PDF Link" onChange={(e) => setCertificatePDFLink(e.target.value)} />
                        <span style={{ color: "red" }}>{certificatePDFLinkError}</span>
                    </div>

                    <div style={{ margin: "20px" }}>
                        <label htmlFor="randomId">Random ID: </label>
                        <input type="text" id="randomId" value={randomId} disabled />
                        <button type="button" onClick={generateId}>Generate ID</button>
                        <span style={{ color: "red" }}>{randomIdError}</span>
                    </div>
                    <div style={{ margin: "20px" }}>
                        <input type="submit" value="Submit" />
                    </div>
                </form>
            </div>
        );
    } else {
        return (
            <div style={{ textAlign: "center" }}>
                <nav style={{ height: "80px", backgroundColor: "blue", paddingTop: "20px" }}>
                    <h1 style={{ fontSize: "36px", color: "white", marginTop: "auto" }}>Worqhat</h1>
                </nav>
                <div style={{ margin: "20px" }}>
                    <h1>404 Page Not Found</h1>
                </div>
            </div>
        );
    }
};

export default FirstPage;