import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SecondPage from "./SecondPage";
import FirstPage from "./FirstPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/364745732534244/:random_id" element={<FirstPage />} />
        <Route path="/verify/:random_id" element={<SecondPage />} />
        <Route path="/share/:random_id" element={<SecondPage />} />
      </Routes>
    </Router>
  );
}

export default App;
